import Helper from './Helper';

class FocusHandler {
  constructor() {
    this.body = document.querySelector('body');
    Helper.addClass(this.body, 'no-focus');
    this.body.addEventListener('keyup', this.checkTabPress);
  }

  checkTabPress(e) {
    "use strict";
    e = e || event;
    if (e.keyCode == 9) {
      Helper.removeClass(document.querySelector('body'), 'no-focus');
    }
  }

}

export default FocusHandler;
