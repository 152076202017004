class SwitchButton {
  constructor(config = {}) {
    const {} = config;
    this.switchButtons = Array.prototype.slice.call(document.querySelectorAll('.wp-button--switch'));

    if (this.switchButtons && this.switchButtons.length !== 0) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.switchButtons.forEach( button => {
      button.addEventListener('click', (e) => {
        if (button.classList.contains('wp-button--active')) {
          button.classList.remove('wp-button--active')
        } else {
          button.classList.add('wp-button--active')
        }
      });
    });
  }
}

export default SwitchButton;
