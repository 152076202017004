import Helper from './Helper';

class IzAnchor {
  constructor(config = {}) {
    const {
      anchorSelector
    } = config;
    this.links = document.querySelectorAll(anchorSelector);
    this.linksArray = Array.prototype.slice.call(this.links);
    if (this.links.length > 0) {
      this.change();
    }
  }

  change() {
    this.linksArray.forEach((link, i) => {
      const idName = link.innerText;
      if (link.nextElementSibling) {
        link.nextElementSibling.id = idName;
      } else {
        link.previousElementSibling.id = idName;
      }
      console.log("anchor set");
      link.remove();
    });
  }

}

export default IzAnchor;

