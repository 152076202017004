import Helper from './Helper';

class ResponsiveTable {
	constructor(config = {}) {
		const {
			tableSelector,
			tableWrapperSelector,
			breakPointLg,
			breakPointMd,
			breakPointSm,
			colCountNoSplit,
			colCountEarlyBreak,
			noSplitArray
		} = config;
		this.breakPointLg = breakPointLg;
		this.breakPointMd = breakPointMd;
		this.tableWrapperSelector = tableWrapperSelector;

		this.tables = document.querySelectorAll(tableSelector);
		this.tablesArray = Array.prototype.slice.call(this.tables);

		if (this.tables) {
			this.tablesArray.forEach(table => {

				this.moveFootnotes(table.parentElement.parentElement.querySelector('.ns-footnote-container'));
				const colCount = this.countCols(table);
				if (colCount <= colCountNoSplit) {
					Helper.addClass(table.closest(this.tableWrapperSelector), 'nosplit');
				}

				if (colCount >= colCountEarlyBreak) {
					Helper.addClass(
						table.closest(this.tableWrapperSelector),
						'js-table-early-break'
					);
				}
			});
			this.bindEvents();
			if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
				var evt = document.createEvent('UIEvents');
				evt.initUIEvent('resize', true, false, window, 0);
				window.dispatchEvent(evt);
			} else {
				window.dispatchEvent(new Event('resize'));
			}
		}
	}

	bindEvents() {
		window.addEventListener('resize', () => {
			const vw = window.innerWidth;
			if (vw < this.breakPointLg && vw >= this.breakPointMd) {
				const earlyBreakArray = Array.prototype.slice.call(
					document.querySelectorAll('.js-table-early-break')
				);
				earlyBreakArray.forEach(earlyBreakTable => {
					const hasClone = earlyBreakTable.querySelector('.clone');
					if (hasClone) {
						// console.log('has Clone');
					} else {
						this.createClone(earlyBreakTable.querySelector('table'));
					}
				});
			} else if (vw < this.breakPointMd) {
				const notNoSplitArray = Array.prototype.slice.call(document.querySelectorAll(`${this.tableWrapperSelector}:not(.nosplit)`));
				notNoSplitArray.forEach(noNoSplitTable => {
					const hasClone = noNoSplitTable.querySelector('.clone');
					if (hasClone) {
						// console.log('has Clone');
					} else {
						this.createClone(noNoSplitTable.querySelector('table'));
					}
				});
			} else {
				const notNoSplitArray = Array.prototype.slice.call(document.querySelectorAll(`${this.tableWrapperSelector}:not(.nosplit)`));
				notNoSplitArray.forEach(noNoSplitTable => {
					const hasClone = noNoSplitTable.querySelector('.clone');
					if (hasClone) {
						noNoSplitTable.querySelector('.clone').remove();
						Helper.removeClass(noNoSplitTable, 'js-responsive-table');
					}
				});
			}
		});
	}

	createClone(table) {

		const cellsArray = Array.prototype.slice.call(
			table.querySelectorAll('tr td:first-of-type')
		);
		cellsArray.forEach(cell => {
			Helper.addClass(cell, 'fixed');

			if (cell.rowSpan >= 2) {
				const numRowSpan = parseInt(cell.rowSpan);
				const currentRow = this.getChildIndex(cell.parentElement);

				for (let i = 0; i <= numRowSpan; i++) {
					const selector = table.querySelector(`tr:nth-of-type(${currentRow + i}) td:first-of-type:not([rowspan="${numRowSpan}"])`);
					if (selector) {
						Helper.addClass(selector, 'unfixed');
					}
				}
			}

			if (Helper.hasClass(cell, 'fixed') && Helper.hasClass(cell, 'unfixed')) {
				Helper.removeClass(cell, 'fixed');
			}
		});


		const wrapper = table.closest(this.tableWrapperSelector);
		Helper.addClass(wrapper, 'js-responsive-table');

		table.querySelector('style').remove();
		table.querySelector('colgroup').remove();

		const clone = table.cloneNode(true);
		Helper.addClass(clone, 'clone');
		const parent = table.parentNode;

		parent.parentNode.insertBefore(clone, parent.nextSibling);

	}

	moveFootnotes(footnote) {
		if (!footnote) return;
		footnote.parentNode.parentNode.appendChild(footnote);
	}

	countCols(table) {
		let max = 0;
		for (var i = 0; i < table.rows.length; i++) {
			if (max < table.rows[i].cells.length) max = table.rows[i].cells.length;
		}
		return max;
	}

	getChildIndex(child) {
		const parent = child.parentNode;
		const children = parent.children;
		let i = children.length - 1;
		for (i = i; i >= 0; i--) {
			if (child == children[i]) {
				break;
			}
		}
		return i;
	}
}

export default ResponsiveTable;
