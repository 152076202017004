class StripPDFComponents {
	constructor({selector}) {

		try {
			const pdfElements = document.querySelectorAll(selector);
			pdfElements.forEach(e => e.remove());
		} catch (error) {
			console.error(e);
		}
	}
}

export default StripPDFComponents;
