/**
 *                                          __  __
 *    ____  ______      ______ _      __   / /_/ /_  ___  ____ ___  ___
 *   / __ \/ ___/ | /| / / __ \ | /| / /  / __/ __ \/ _ \/ __ `__ \/ _ \
 *  / / / (__  )| |/ |/ / /_/ / |/ |/ /  / /_/ / / /  __/ / / / / /  __/
 * /_/ /_/____/ |__/|__/\____/|__/|__/   \__/_/ /_/\___/_/ /_/ /_/\___/
 *
 * ns.wow WordPress theme
 * by Neidhart + Schön Group
 */




import Helper from './Classes/Helper';
import FocusHandler from './Classes/FocusHandler';
import LazyLoad from 'vanilla-lazyload';
import ResponsiveTable from "./Classes/ResponsiveTable";
import CountUpNumbers from './Classes/CountUpNumbers';
import Video from "./Classes/Video";
//import XDownloader from "./Classes/XDownloader";
import Mark from "mark.js";


import Accordion from './Classes/Accordion';
import Cv from "./Classes/Cv";
import MenuCore from './Classes/MenuCore';
import MenuB from './Classes/MenuB';
import SwitchButton from './Classes/SwitchButton';
import BackToTop from "./Classes/BackToTop";
import IzAnchor from "./Classes/IzAnchor";
import StripPDFComponents from './Classes/StripPDFComponents';
import BrowserDetection from "./Classes/BrowserDetection";

// import Jump from './Classes/Jump'
// import Sticky from '../../node_modules/sticky-js';
// import YoutubeLinks from './Classes/YoutubeLinks';


var focusHandlerInstance = null;
var lazyLoadInstance = null;
var cvInstance = null;
var responsiveTableInstance = null;
var downloaderInstance = null;

var accordionInstance = null;
var jumpInstance = null;
var youtubeLinksInstance = null;

var countUpInstance = null;
var videoInstance = null;

var headerInstance = null;
var switchButtonInstance = null;
var anchorInstance = null;
var backToTopInstance = null;

const consoleDebug = console.debug;


// Initialize on document ready

window.addEventListener("page_event_load", () => {
	focusHandlerInstance = new FocusHandler();

	//lazyLoadInstance = new LazyLoad();

	if (cvInstance) {
		cvInstance.unbindEvents();
		cvInstance.bindEvents();
	}
	else {
		cvInstance = new Cv();
	}

	anchorInstance = new IzAnchor({
		anchorSelector: '.lc-anchor'
	});

	responsiveTableInstance = new ResponsiveTable({
		tableSelector: '.lc-table-wrap table',
		tableWrapperSelector: '.lc-table-wrap',
		breakPointLg: 1200,
		breakPointMd: 768,
		breakPointSm: 480,
		colCountNoSplit: 2,
		colCountEarlyBreak: 7,
		noSplitArray: []
	});

	new StripPDFComponents({
		selector: '.lc-pdf-chapter-title, .lc-pdf-publication-title, .lc-pdf-pagebreak',
	})
	/*
		searchInstance = new Search({
			resultContainerQuery: 'body.search .wp-search-results',
			loadMoreQuery: '.js-search-load-more',
			loadingSpinnerQuery: 'asdasdasdasdsa',
			totalCountQuery: 'body.search .wp-search__text--count',
			loadingSpinnerActiveClass: 'active',
			loadMoreActiveClass: 'active',
			resultsPerPage: 10,
		});

		/*
		headerInstance = new MenuB({
			bSkipLevel1: true
		});
	*/
	// downloaderInstance = new XDownloader({
	//   downloadText: "Download Excel", //Can be empty if you want to use an icon instead
	//   backgroundColor: "6ab04c", //Colored Cells Background
	//   backgroundClass: 'ns-backgroundcolor-gf_3a-green', //Class of colored cells
	//   borderColor: '95afc0', //Color of border
	//   borderStyle: 'thin', // thin/dotted/medium/double/thick
	//   headClass: 'head', //Class you want your columns based on. Will be bold in Excel
	//   boldClass: 'title', //Class for Bold text in Excel
	//   borderClass: 'ns-borderbottom-line_dots'
	// });

	accordionInstance = new Accordion({
		accordions: '.lc-accordion__inner-head',
		pxCorrectionMobile: 110,
		pxCorrectionDesktop: 105,
		mobileBreakpoint: 1025
	});


	// downloaderInstance = new XDownloader({
	//   downloadText: "Download Excel", //Can be empty if you want to use an icon instead
	//   backgroundColor: "6ab04c", //Colored Cells Background
	//   backgroundClass: 'ns-backgroundcolor-gf_3a-green', //Class of colored cells
	//   borderColor: '95afc0', //Color of border
	//   borderStyle: 'thin', // thin/dotted/medium/double/thick
	//   headClass: 'head', //Class you want your columns based on. Will be bold in Excel
	//   boldClass: 'title', //Class for Bold text in Excel
	//   borderClass: 'ns-borderbottom-line_dots'
	// });


	// jumpInstance = new Jump({
	//   graphicSelector: '.ns-graphic'
	// });

	// youtubeLinksInstance = new YoutubeLinks({
	// });

	// countUpInstance = new CountUpNumbers({
	// 	numbersSelector: '.lc-keyfigure__number',
	// 	classIn: 'is-in-viewport',
	// 	classOut: 'is-not-in-viewport',
	// 	langArray: ['de-DE', 'en-US'],
	// 	decimalArray: ['.', '.'],
	// 	thousandSepArray: ['', '’'],
	// });

	videoInstance = new Video();

	switchButtonInstance = new SwitchButton();

	backToTopInstance = new BackToTop({
		buttonClass: '.iz-to-top',
		stickyClass: '.iz-to-top-sharing',
		fadeInPosition: 100,
		correction: 0,
		toolbarClass: '.iz-to-top-sharing'
	});

	if (window.location.search) {
		const queryQueryParams = new URLSearchParams(window.location.search);
		const highlight = queryQueryParams.get("highlight");
		if (highlight && highlight.trim() !== "") {
			const markInstance = new Mark(document.querySelector(".iz-content__content"));
			const words = queryQueryParams.get("highlight");
			console.log(words)

			// console.log("highlighting", words);

			markInstance.mark(words, {
				element: "span",
				className: "iz-highlighted-item",
				caseSensitive: false,
				accuracy: "partially",
				ignoreJoiners: true,
				done: (counter) => {
					console.info("markjs matches", counter);
					console.log(document.querySelectorAll('.iz-highlighted-item'))
				},
				noMatch: (term) => {
					console.info("markjs nomatch:", term);
				},
			});
		}
	}

});

window.addEventListener('note_event_load', (e) => {

	responsiveTableInstance = new ResponsiveTable({
		tableSelector: '#page-dialog .iz-notes__content .lc-table-wrap table',
		tableWrapperSelector: '.lc-table-wrap',
		breakPointLg: 1200,
		breakPointMd: 768,
		breakPointSm: 480,
		colCountNoSplit: 2,
		colCountEarlyBreak: 7,
		noSplitArray: []
	});
})


// support console function
window.addEventListener("load", () => {
	window.openAccorodions = () => {
		const accordions = document.querySelectorAll(".lc-accordion__inner-head, .lc-cv__button-container");
		accordions.forEach(acc => {
			try {
				acc.click()
			} catch (error) {
				console.error(error);
			}
		})
	};

  new BrowserDetection();
})
