import Helper from './Helper';

class BackToTop {
	constructor(config = {}) {
		const {
			buttonClass,
			stickyClass,
			fadeInPosition,
			correction,
			toolbarClass
		} = config

		this.pos = fadeInPosition || 500;
		this.scrollPos = 0;
		this.buttonClass = buttonClass;
		this.toolbarClass = toolbarClass;
		const buttonElement = document.querySelector(this.buttonClass);
		if (buttonElement) {
			buttonElement.addEventListener('click', e => {
				window.scrollTo({top: 0, behavior: 'smooth'});
			});

			this.checkScrollDirection();
		}
	}

	checkScrollDirection() {
		this.toolbar = document.querySelector(this.toolbarClass);
		const toolbar = this.toolbar;
		let scrollPos = this.scrollPos;
		let isScrolling;

		window.addEventListener('scroll', (e) => {
			window.clearTimeout(isScrolling);

			// Set a timeout to run after scrolling ends
			isScrolling = setTimeout(function () {
				// Run the callback
				Helper.addClass(toolbar, 'scroll-stop');
			}, 66);


			if ((document.body.getBoundingClientRect().top) > scrollPos) {
				// UP
				Helper.removeClass(toolbar, 'scroll-down');
				Helper.removeClass(toolbar, 'scroll-stop');
				Helper.removeClass(toolbar, 'at-top');
				Helper.addClass(toolbar, 'scroll-up');
			} else {
				//DOWN
				Helper.removeClass(toolbar, 'scroll-up');
				Helper.removeClass(toolbar, 'scroll-stop');
				Helper.removeClass(toolbar, 'at-top');
				Helper.addClass(toolbar, 'scroll-down');

			}
			scrollPos = (document.body.getBoundingClientRect().top);

			if (scrollPos === 0 || scrollPos === 32) {
				//at top
				Helper.addClass(toolbar, 'at-top');
			}

		});
	}
}

export default BackToTop;
